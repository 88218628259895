import React, { useEffect } from 'react'
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
  withRouter,
} from 'react-router-dom'

import Home from '../pages/Home'
// import Code from '../pages/Code'
import Layout from '../layout'

function _ScrollToTop(props) {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

const App = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/codigoconducta" component={Code} /> */}
        </Switch>
      </Layout>
    </ScrollToTop>
  </BrowserRouter>
)

export default App
