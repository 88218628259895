import React from 'react'
import ReactDOM from 'react-dom'
import GlobalFonts from './assets/fonts'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router'
import { Normalize } from 'styled-normalize'
import App from './routes'
import reportWebVitals from './reportWebVitals'

const history = createBrowserHistory()

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Normalize />
      <GlobalFonts />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
