import React, { useEffect } from 'react'
import styled from 'styled-components'
// import { GoPrimitiveDot } from 'react-icons/go'
import bg from '../../assets/static/bg.svg'
import arrow from '../../assets/static/arrow.svg'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Inicio = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <InicioS id="home">
      <div>
        <H1>En Construcción</H1>
        <Ul>
          <li>Volvemos pronto</li>
        {/*   <li>
            <GoPrimitiveDot />
          </li>
          <li>Sitios Web</li>
          <li>
            <GoPrimitiveDot />
          </li>
          <li>Sistemas especializados</li>
          <li>
            <GoPrimitiveDot />
          </li>
          <li>Tiendas en Línea</li> */}
        </Ul>
      </div>
      {/* <Img src={arrow} alt="" /> */}
    </InicioS>
  )
}

export default Inicio

const H1 = styled.h1`
  font-weight: 200;
  max-width: 100vw;
  font-size: 6rem;
  text-align: center;
  margin-top: 50px;
  min-height: 53px;
  color: #4f4f4f;
  z-index: 1;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 768px) {
    font-size: 3.5rem;
    padding: 0 20px;
    text-align: center;
  }
`

const Ul = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  justify-content: space-around;
  font-weight: 200;
  > li {
    font-size: 1.2rem;
    &:nth-child(even) {
      color: #5791c8;
    }
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

const InicioS = styled.div`
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 0;
  background: #fff;
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  background-image: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) 0%,
      rgba(235, 234, 234, 0.6) 47.43%,
      rgba(217, 217, 217, 0) 100%
    ),
    url(${bg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 768px) {
    background-size: cover;
  }
`

const Img = styled.img`
  position: absolute;
  bottom: 20px;
  width: 80px;
  @media (max-width: 768px) {
    width: 80px;
  }
`
