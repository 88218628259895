import React from 'react'
import Inicio from './Inicio'
// import Nosotros from './Nosotros'
// import NuestrosServicios from './NuestrosServicios'
// import Counters from './Counters'
// import NuestrosProyectos from './NuestrosProyectos'
// import Contacto from './Contacto'
// import Brands from './Brands'

const Home = () => {
  return (
    <>
      <Inicio />
    {/*   <Nosotros />
      <NuestrosServicios />
      <Counters />
      <NuestrosProyectos />
      <Brands />
      <Contacto /> */}
    </>
  )
}

export default Home
